export default [
  {
    title: "EmpCode",
    key: "EmpCode",
    minWidth: 50,
  },
  {
    title: "Name",
    key: "name",
    width: 150,
  },
  
  {
    title: "Designation",
    key: "Designation",
    sortable: true,
    minWidth: 80,
  },
  {
    title: "Grade",
    key: "Grade",
    sortable: true,
    minWidth: 80,
  },
  {
    title: "BankAccNo",
    key: "BankAccNo",
    minWidth: 80,
  },
  {
    title: "Basic",
    minWidth: 80,
    render: (h, params) => {
      return h('span',params.row.Basic?parseFloat( params.row.Basic).toFixed(2):'');
  },
  },
  {
    title: "HRA",
    minWidth: 80,
    render: (h, params) => {
      return h('span',params.row.HRA?parseFloat( params.row.HRA).toFixed(2):'');
  },
  },
  {
    title: "PF",
    minWidth: 50,
    render: (h, params) => {
      return h('span',params.row.PF?parseFloat( params.row.PF).toFixed(2):'');
  },
  },
  // {
  //   title: "Additional",
  //   minWidth: 80,
  //   render: (h, params) => {
  //     return h('span',params.row.Additional?parseFloat( params.row.Additional).toFixed(2):'');
  // },
  // },
  {
    title: "Conveyance",
    minWidth: 80,
    render: (h, params) => {
      return h('span',params.row.Conveyance?parseFloat( params.row.Conveyance).toFixed(2):'');
  },
  },
  {
    title: "Medical Reimbursement",
    minWidth: 80,
    render: (h, params) => {
      return h('span',params.row.MedicalReimb?parseFloat( params.row.MedicalReimb).toFixed(2):'');
  },
  },
  // {
  //   title: "Special",
  //   minWidth: 80,
  //   render: (h, params) => {
  //     return h('span',params.row.Special?parseFloat( params.row.Special).toFixed(2):'');
  // },
  // },
  {
    title: "Total Deduction",
    minWidth: 60,
    render: (h, params) => {
      return h('span',params.row.TotalDeduction?parseFloat( params.row.TotalDeduction).toFixed(2):'');
  },
  },
  {
    title: "Net Payable",
    minWidth: 80,
    render: (h, params) => {
      return h('span',params.row.NetPayable?parseFloat( params.row.NetPayable).toFixed(2):'');
  },
  },
];
