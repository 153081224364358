<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Salary Processing"
      slot="title"
      link="/helpContent/salaryProcessing"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-4">
          <FormSelect
            label="Company"
            :items="companyList"
            item-name="Name"
            rules="required"
            item-value="id"
            v-model="filtering.companyId"
          />
        </div>
        <div class="col-sm-2">
          <Year label="Year" v-model="filtering.year" rules="required" />
        </div>
        <div class="col-sm-2">
          <InputMonth
            label="Month"
            :items="monthsList"
            item-name="NAME"
            item-value="id"
            v-model="filtering.month"
          />
        </div>
        <div class="col-sm-3">
          <FormButton
            style="margin-top: 5px"
            type="primary"
            @click="search"
            :rounded="true"
          >
            <Icon type="ios-search" />
          </FormButton>
        </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      :pagination="pagination"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
    >
      <template slot="actionsLeft">
        <TableButton @click="() => exportExcel()">
          <img :src="require(`Assets/images/Excel_Icon.png`)" />
          Export
        </TableButton>
        <TableButton @click="() => freezeMonth()">
          <img :src="require(`Assets/images/lock.png`)" />
          Freeze Month
        </TableButton>
      </template>
    </DataTable>
    <Popup title="Information" :value="warningModal.visible" @close="() => showWarningModal(false)">
        {{ warningModal.message }}
        <div slot="footer">
            <FormButton v-if="warningModal.buttons.cancel" @click="() => showWarningModal(false)">Cancel</FormButton>
            <FormButton v-if="warningModal.buttons.confirm" type="success" @click="confirmFreeze">Confirm</FormButton>
            <FormButton v-if="warningModal.buttons.ok" @click="() => showWarningModal(false)">Ok</FormButton>
        </div>
    </Popup>
    <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import validateSelectedRows from "Mixins/validateSelectedRows";
import {
  getAllCompanyList,
  getProcessMonthlySalary,
  freezeSalary,
} from "../api";
import tableConfig from "./table";
import Year from "../../../components/Year";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    Year,
    ValidationObserver,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      filtering: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        companyId: null,
      },
      staffList: [],
      monthsList: [],
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      tableConfig,
      listData: [],
      selectedRows: [],
      companyList: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  created() {
    this.getCompany();
  },
  methods: {
    async getCompany() {
      this.showLoader();
      await getAllCompanyList().then((res) => {
        this.companyList = res.data;
      });
      this.hideLoader();
    },
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    getDataByFilter() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          setTimeout(() => {
            // this.showLoader();
            const { key, order } = this.sorting;
            const { perPage, page } = this.pagination;

            const filters = {};
            filters.companyId = this.filtering.companyId;
            filters.year = this.filtering.year;
            filters.month = this.filtering.month;
            const data = {
              filterjson: {
                filter: [filters],
                sort: [{ key, order }],
                paging: [
                  {
                    startIndex: page,
                    pagesize: perPage,
                  },
                ],
              },
            };

            getProcessMonthlySalary(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }, 50);
        }
      });
    },
    freezeMonth() {
      if (this.listData.length>0) {
        this.warningModal.message = 'Are you sure you want to freeze this month data.';
        this.warningModal.buttons.ok =false;
        this.warningModal.buttons.cancel =true;
        this.warningModal.buttons.confirm =true;
        this.showWarningModal(true);
        return false
      }
    },
    confirmFreeze(){
        this.showWarningModal(false);
        const data = {
          staffId: this.selectedRows[0]?this.selectedRows[0].Staff_id:'',
          companyId: this.filtering.companyId,
          year: this.filtering.year,
          month: this.filtering.month,
        };
        freezeSalary(data).then((res) => {
          if (res.data[0].status > 1) {
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          } else {
            this.showWarningModal(true);
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.warningModal.message = res.data[0].message;
          }
        });
      },
    exportExcel() {
      if (this.listData.length>0) {
              const { key, order } = this.sorting;
            const { perPage, page } = this.pagination;

            const filters = {};
            filters.companyId = this.filtering.companyId;
            filters.year = this.filtering.year;
            filters.month = this.filtering.month;
            filters.excelExport = 1;
            const data = {
              filterjson: {
                filter: [filters],
                sort: [{ key, order }],
                paging: [
                  {
                    startIndex: page,
                    pagesize: perPage,
                  },
                ],
              },
            };

            getProcessMonthlySalary(data)
                .then(this.handleExportResponse)
                .catch(this.handleError);
  
          }
    },
    handleExportResponse(response) {
          window.open(response.data.url,'_blank');
      },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.listData = response.data;
      this.pagination.total = response.NumberOfRecords;
    },
  },
};
</script>

<style lang="scss" module>
.popupRow {
  padding: 5px 0 15px;
}
.pagination {
  margin: 10px 0 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
}

.paginationText {
  margin: 0 20px;
}

.paginationButton {
  cursor: pointer;
  width: 26px;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 200ms ease;
}
.paginationButton:hover {
  background: #57a3f3;
  color: #fff;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}
</style>
